<template>
    <form @submit.prevent="submitUserForm">
        <Box :title="$t('order.userInputs.name_lastname')">
            <input
            v-model="userData.name"
            class="form-control"
            :class="isValidTextClass(userData.name, 4, step2BtnClicked)"
            type="text"
            required
            :placeholder="$t('order.userInputs.name_lastname')"
            @blur="isValidTextField(userData.name, 4) && pushGTM('funnel', 'nombre', 'paso2', '', productName)"
            />
        </Box>
        <Box :title="$t('order.userInputs.email')">
            <input
            v-model="userData.email"
            class="form-control"
            :class="isValidEmailClass(userData.email, step2BtnClicked)"
            type="email"
            required
            :placeholder="$t('order.userInputs.email_placeholder')"
            @blur="pushGTMEmailInfo()"
            />
        </Box>
        <Box :title="$t('order.userInputs.birth_date')" class="box">
            <b-form-datepicker placeholder="dd/mm/aaaa" :class="isValidBirthDateClass(userData.birth_date, step2BtnClicked)" :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }" :max="maxDate" show-decade-nav hide-header  locale="es" @input="updateBirthDate($event)"></b-form-datepicker>
        </Box>
        <Box :title="$t('order.userInputs.nif')">
            <input
            v-model="userData.nif"
            :class="isValidNifNieClass(userData.nif, step2BtnClicked)"
            type="text"
            required
            :disabled="userData.birth_date.length == 0 || !isValidBirthDate(userData.birth_date)" class="form-control" @focus="setNifAutocompleted()"
            @blur="isValidNifNie(userData.nif) && pushGTM('funnel', 'nif', 'paso2', '', productName)"
            />
        </Box>
        <Box :title="$t('order.userInputs.postal_code')">
            <input
            v-model="userData.postal_code"
            class="form-control"
            :class="isValidNumberClass(userData.postal_code, 4, step2BtnClicked)"
            type="number"
            onkeydown="return event.keyCode !== 69"
            required
            :placeholder="$t('order.userInputs.postal_code')"
            />
        </Box>
        <Box :title="$t('order.userInputs.state')">
            <input
            v-model="userData.state"
            class="form-control"
            :class="isValidTextClass(userData.state, 3, step2BtnClicked)"
            type="text"
            required
            :placeholder="$t('order.userInputs.state')"
            />
        </Box>
        <Box :title="$t('order.userInputs.town')">
            <input
            v-model="userData.town"
            class="form-control"
            :class="isValidTextClass(userData.town, 3, step2BtnClicked)"
            type="text"
            :placeholder="$t('order.userInputs.town')"
            />
        </Box>
        <Box :title="$t('order.userInputs.colony')">
            <input
            v-model="userData.colony"
            class="form-control"
            :class="isValidTextClass(userData.colony, 3, step2BtnClicked)"
            type="text"
            required
            :placeholder="$t('order.userInputs.colony')"
            />
        </Box>
        <Box :title="$t('order.userInputs.street')">
            <input
            v-model="userData.street"
            class="form-control"
            :class="isValidTextClass(userData.street, 3, step2BtnClicked)"
            type="text"
            required
            :placeholder="$t('order.userInputs.street')"
            />
        </Box>
        <Box :title="$t('order.userInputs.exterior_number')">
            <input
            v-model="userData.exterior_number"
            class="form-control"
            :class="isValidNumberClass(userData.exterior_number, 1, step2BtnClicked)"
            type="number"
            onkeydown="return event.keyCode !== 69"
            required
            :placeholder="$t('order.userInputs.exterior_number')"
            />
        </Box>
        <Box :title="$t('order.userInputs.interior_number')">
            <input
            v-model="userData.inner_number"
            class="form-control"
            type="number"
            onkeydown="return event.keyCode !== 69"
            required
            :placeholder="$t('order.userInputs.interior_number')"
            />
        </Box>
        <div class="checks">
            <div class="check">
                <input v-model="userData.acceptance_type" type="checkbox" id="acceptance_type1" @change="pushGTMTerms(userData.acceptance_type,'terminos_1')">
                <span class="checkmark"></span>
                <label :class="isValidAcceptanceTypeClass(userData.acceptance_type, step2BtnClicked)" v-html="$t('order.userInputs.terms_1', { landing: this.currentLanding, termsAndConditionsPath: this.termsAndConditionsPath, privacyPolicyPath: this.privacyPolicyPath  })"></label>
            </div>
            <div class="check">
                <input v-model="userData.acceptance_type_2" type="checkbox" id="acceptance_type2" @change="pushGTMTerms(userData.acceptance_type_2,'terminos_2')">
                <span class="checkmark"></span>
                <label :class="isValidAcceptanceTypeClass(userData.acceptance_type_2, step2BtnClicked)" v-html="$t('order.userInputs.terms_2', { landing: this.currentLanding })"></label>
            </div>
        </div>
        <button :disabled="loading" type="button" class="btn btn-block btn-linear btn-siguiente" @click="submitUserForm">
            <template v-if="!loading">
                <span>{{$t('contractingLanding.contract_insurance')}}</span>
                <img class="cont-img ml-1" src="../../assets/img/contracting/top.svg" alt="">
            </template>
            <template v-else>
                <img src='@/assets/loader.gif' height=20>
            </template>
        </button>
    </form>
</template>

<script>
import Box from './Box.vue';
import dayjs from 'dayjs';

export default {
    name: 'UserForm',
    components:{
        Box
    },
    data(){
        return{
            maxDate: dayjs().format('YYYY-MM-DD'),
            userData: {
              name: '',
              email: '',
              nif: '',
              postal_code: '',
              birth_date: '',
              state: '',
              town: '',
              colony: '',
              street: '',
              exterior_number: '',
              inner_number: '',
              acceptance_type: '',
              acceptance_type_2: '',
            },
             step2BtnClicked: false,
             loading: false,
        }
    },
    props: {
        privacyPolicyPath: String,
        termsAndConditionsPath: String,
    },
    methods: {
        updateBirthDate(date){
            this.userData.birth_date = dayjs(date).format('DD/MM/YYYY');
            if(this.isValidBirthDate(this.userData.birth_date)) this.pushGTM('funnel', 'fecha_nacimiento', 'paso2', '', this.productName);
        },
        setNifAutocompleted() {
            if (this.userData.nif.length > 0) return;

            if (this.userData.name.length > 0) {
                let arrayName = this.userData.name.split(' ');
                let secondlastname = arrayName[arrayName.length - 1];
                let firstlastname = arrayName[arrayName.length - 2];
                let name = arrayName[0];

                let startLettersSecondlastname = secondlastname.substring(0,1).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
                let startLettersFirstlastname = firstlastname.substring(0,2).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
                let startLettersname = name.substring(0,1).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();

                this.userData.nif = startLettersFirstlastname + startLettersSecondlastname + startLettersname
            } else {
                this.userData.nif = 'XXXX'
            }

            if (this.userData.birth_date.length > 0) {
                const year = this.userData.birth_date.substring(8, 10)
                const month = this.userData.birth_date.substring(3, 5)
                const day = this.userData.birth_date.substring(0, 2)
                const number = year+month+day
                this.userData.nif += number
            } else {
                this.userData.nif += 'XXXXXX'
            }

            this.userData.nif +='XXX'
            
        },
        submitUserForm() {
            this.step2BtnClicked = true;
            if(this.isValidOrderStep2(this.userData)){
                this.loading = true;
                this.$emit('contract', this.userData);
            }
        },
        // GTM
        pushGTMEmailInfo() {
            if(this.isValidEmail(this.userData.email)) {
                this.pushGTM('funnel', 'email', 'paso2', '', this.productName);
                this.pushGTMEmail(this.userData.email);
            }
        },
        pushGTMTerms(value, terms){
            this.pushGTM('funnel', terms, 'paso2', value, this.productName);
        },
    }
}
</script>

<style scoped>
    .cont-img{
        filter: brightness(0) invert(1); 
        width: 20px; 
        vertical-align: middle;
    }

    .check{
        display: flex;
        gap:10px;
        font-size: 12px;
        text-align: left;
    }

    .checks{
        margin: 20px 0 20px 0;
    }

    .check >>> a{
        color: #FF4C00;
        text-decoration: underline;
    }
</style>
