<template>
<div>
  <template v-if="!actived">
    <p>{{$t(error)}}</p>
  </template>
  <template v-else>
    <div class="contracting">
      <!-- HEADER WITH LOGOS -->
      <div class="header-contracting">
          <div class="header-logo-wrapper">
              <img class="logo" :src="getInsuramaPath()">
          </div>
          <div>
              <img class="header-separator" src="../assets/contracting-landing/header_separator.svg">
          </div>
          <div class="header-logo-wrapper">
              <img class="logo" :src="landingData.logo_path">
        </div>
      </div>
      <!-- CONTACT -->
      <div class="contact-container">
        <div class="contact-wrapper left">
            <a href="tel:5559854280">5559854280</a>
        </div>

        <div class="contact-wrapper right">
            <span @click="$refs.callRequestRef.showModal()">{{$t('contractingLanding.request_call')}}</span>
        </div>
      </div>

      <!-- CONTRACT -->
      <div class="contract">
        <img :src="getProductPath()" alt="" style="height: 54px;">

        <template v-if="!freeCancelPeriod">
          <h2>{{$t('contractingLanding.contract')}}</h2>
          <p>{{$t('contractingLanding.fill_data')}}</p>
        </template>
        <template v-else>
          <h2>{{$t('contractingLanding.grace_title', {n:freeCancelPeriod})}}</h2>
          <p>{{$t('contractingLanding.grace_text', {n:freeCancelPeriod})}}</p>
        </template>

        <div class="order-tabs" >
          <b-tabs v-model="step" align="center">
          <!-- Product step -->
            <b-tab title="1" @click="disabledUserStep= true">
              <div class="container">
              <!-- Product Form -->
                <div v-if="!showPolicyError && !showPolicySuccess" class="form col-md-6 offset-md-3">
                  <InsurancePrice :title="$t('contractingLanding.price')" :price="price" :anualPrice="anualPrice" currency="$"
                  :periodicity="policyData.periodicity" :monthsWithoutInterest="monthsWithoutInterest" :deferredPaymentMonths="deferredPaymentMonths"></InsurancePrice>
                  <div class="invalidPrice" v-if="policyData.periodicity === '1' && anualPrice < 1200 && !showMaxPurchasePriceError">
                    <span>{{$t('contractingLanding.invalid_price')}}</span>
                  </div>
                  <div class="invalidPrice" v-if="showMaxPurchasePriceError">
                    <span v-if="!isMultidevice()">{{$t('contractingLanding.max_purchase_price_error', {max: maxPurchasePriceLimit})}}</span>
                    <span v-else>{{$t('contractingLanding.max_purchase_price_error_multidevice', {max: policyData.purchasePrice})}}</span>
                  </div>
                  <keep-alive>
                    <component :is="productFormComponent"
                    :showPeriodicityOptions="showPeriodicityOptions"
                    :showMonthlyPeriodicityOption="showMonthlyPeriodicityOption"
                    :initialPeriodicity="policyData.periodicity"
                    :productName="policyData.product.name"
                    :currentLanding="product"
                    :minPurchasePriceLimit="minPurchasePriceLimit"
                    :maxPurchasePriceLimit="maxPurchasePriceLimit"
                    :multideviceLimits="landingData.product.multi_array"
                    :multideviceLimitsText="landingData.product.multi_array_text"
                    @save="savePolicyData($event)"
                    @updatePrice="updatePolicyData($event.periodicity, $event.purchasePrice, $event.risks)">
                    </component>
                  </keep-alive>
                </div>
                <!-- Error -->
                <div v-if="showPolicyError" class="pb-5">
                    <h4>{{ $t('validation.error_ocurred') }}</h4>
                    <p class="error-text">{{ $t('order.error.check_data') }}.</p>
                    <p class="error-text mb-4">{{ $t('order.error.contact_with_us') }}.</p>
                    <button class="btn-orange" @click="showPolicyError=false">{{$t('contractingLanding.retry')}}</button>
                </div>
                <!-- Success -->
                <div v-if="showPolicySuccess" class="pb-5">
                    <h4>{{$t('order.ok.text1')}}</h4>
                    <p class="error-text" v-html="$t('contractingLanding.success_text', {name: $t('order.ok.'+product)})"></p>
                    <p class="error-text">{{ $t('order.ok.text3', {policyNumber: this.policyNumber}) }}</p>
                    <p class="error-text">{{ `${$t('order.ok.text4')} ${$t('order.ok.'+product)}.` }}</p>
                    <p><img height="45" :src="getInsuramaPath()"></p>
                </div>  
              </div>
            </b-tab>
            <!-- User Step -->
            <b-tab title="2" :disabled="disabledUserStep">
              <div class="container">
                <div class="form col-md-6 offset-md-3">
                  <img :src="getPricePath()" alt="" style="width: 38px;">
                  <UserForm
                  :productName="product" :privacyPolicyPath=this.landingData.privacy_policy_path :termsAndConditionsPath=this.landingData.terms_and_conditions_path
                  @contract="createPolicy"></UserForm>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>

      <!-- FOOTER -->
      <div class="contracting-footer">
        <FooterLegality></FooterLegality>
        <div class="container">
            <div class="col-12 claim-legal">
                <strong>{{ $t('footerComponent.copyright') }}</strong>
                <br>
                {{ $t('footerComponent.commercial_register') }}
                <br>
                {{ $t('footerComponent.commercial_register_2') }}
            </div>
        </div>
      </div>
      <!-- DIALOGS -->
      <CallRequestModal ref="callRequestRef" :showSinesterBox="false"></CallRequestModal>
    </div>
  </template>
</div>
</template>
<script>
 import FooterLegality from '@/components/footer/FooterLegality.vue';
 import CallRequestModal from '@/components/modals/CallRequestModal.vue';
 import InsurancePrice from '@/components/landing/InsurancePrice.vue';
 import SmartphoneForm from '@/components/landing/SmartphoneForm';
 import LaptopForm from '@/components/landing/LaptopForm';
 import MultideviceForm from '@/components/landing/MultideviceForm';
 import BoxIcon from '@/components/landing/BoxIcon.vue';
 import UserForm from '@/components/landing/UserForm.vue';
 import { getDistributorLandings, submitPolicy, getPolicyPrices  } from '@/api/ApiClient.js';
 import { getProductCategoryId } from '@/api/ApiClientUtils.js'
 import { getPolicyPriceParams, getSubmitPolicyParams } from '@/api/ApiClientParamsSetter.js';
 import landingsIds from '@/config/constants/landing.js';
import { getters } from '@/state/store';

export default {
  name: 'Contracting',
  components: {
    FooterLegality,
    CallRequestModal,
    BoxIcon,
    InsurancePrice,
    SmartphoneForm,
    LaptopForm,
    MultideviceForm,
    UserForm
  },
  props: {
    landing: {
      type: String,
      required: true,
    },
    product: {
      type: String,
      required: true,
    },
    distributor: {
      type: String,
      required: true,
    },
    tpvOk: {
      type: Number,
      default: undefined,
    },
    policyNumber: {
      type: String,
      default: undefined,
    }
  },
  data() { 
    return {
      actived: false,
      error: undefined,
      step: 0,
      disabledUserStep: true,
      productFormComponent: undefined,
      showMaxPurchasePriceError: false,
      showPolicyError: false,
      showPolicySuccess: false,

      // Data
      landingData: undefined,
      price: undefined,
      anualPrice: undefined,
      policyData: undefined,
      userData: undefined,
      monthsWithoutInterest: undefined,

      minPurchasePriceLimit: undefined,
      maxPurchasePriceLimit: undefined,

      // Flags
      showPeriodicityOptions: false,
      showMonthlyPeriodicityOption: true,
      freeCancelPeriod: undefined,
    }
  },
  created() {
    if(this.tpvOk)
      this.checkTpvResponse();

    this.setFavicon();
    this.setTitle();
    this.getLandingData();
    this.getCurrentDeviceComp();
  },
  methods: {
      checkTpvResponse(){
        this.showPolicySuccess = this.tpvOk == 1;
        if(this.showPolicySuccess) return;

        this.showPolicyError = true;
      },
      setFavicon() {
          var favicon = document.querySelector("link[rel~='icon']");
          favicon.href = require(`@/assets/fav-icons/${this.$i18n.locale}_${this.getGeneralProduct()}.png`)
      },
      setTitle() {
        document.title = landingsIds[this.product]?.title;
      },
      async getLandingData(){
        try {
          const landings = await getDistributorLandings(this.distributor);
          this.landingData = landings.find(({url})=>{
            const landingUrl = url.split('/')[1];
            return landingUrl === this.landing;
            });

        } catch {
          this.handleError();
        }
        
        this.isValidLanding();
        if(!this.actived) return;
        this.setLandingFlags();
        this.initPolicyData();
        this.getProductPrice();
      },

      isValidLanding() {
        // Check if the landing is actived
        this.actived = this.landingData?.actived == 1;
        this.error = !this.actived && 'contractingLanding.not_activated_error';
        if(!this.actived) return;

        //Check if the product is valid
        const productTypeId = landingsIds[this.product]?.product_type_id;
        this.landingData.product = this.landingData.products.find((product)=>product.product_type_id == productTypeId);
        this.actived = productTypeId && this.landingData.product;
        this.error = !this.actived && 'contractingLanding.not_product_error';
      },

      setLandingFlags() {
        this.showPeriodicityOptions = this.landingData.payment_method === null;

        const grace = this.landingData.graces.find((grace)=>grace.product_type_id === this.landingData.product.product_type_id);
        this.freeCancelPeriod = grace && grace.months_of_grace;
      },

      initPolicyData() {
        this.policyData = {
          periodicity: this.landingData.payment_method || "1",
          store_id: this.landingData.store_id,

          product: this.landingData.product, // !multidispositivo
          product_id: this.landingData.product.product_id // multidispositivo
        }

        this.setMinPurchasePriceLimit();
        this.setMaxPurchasePriceLimit();

        this.policyData.purchasePrice= this.minPurchasePriceLimit
        this.policyData.capital= { amount: this.minPurchasePriceLimit};
      },

      setMinPurchasePriceLimit() {
        const product = getters.getProducts().find(i => i.id === this.policyData.product_id);
        if(this.policyData.periodicity === "12") {
          const prices = product.prices.map((price)=> price.min_value);
          this.minPurchasePriceLimit = Math.min(...prices);
        } else {
          const productsPriceOver1200 = product.prices.filter((price)=> parseFloat(price.pivot.price)>1200);
          const prices = productsPriceOver1200.map((price)=> price.min_value);
          this.minPurchasePriceLimit = Math.min(...prices);
        }
      },
      setMaxPurchasePriceLimit() {
        const product = getters.getProducts().find(i => i.id === this.policyData.product_id);
        const prices = product.prices.map((price)=> price.max_value);
        this.maxPurchasePriceLimit = Math.max(...prices);
      },

      updatePolicyData(periodicity, purchasePrice, risks) {
        if(periodicity) this.policyData.periodicity = periodicity;
        let oldMinLimit = this.minPurchasePriceLimit;
        this.setMinPurchasePriceLimit();
        if(purchasePrice) {
          this.policyData.purchasePrice = purchasePrice;
          this.policyData.capital.amount = purchasePrice;
        }

        if(risks){
          this.policyData.risks = risks;
        }

        if(this.policyData.purchasePrice === oldMinLimit && this.showPeriodicityOptions) {
          this.policyData.purchasePrice= this.minPurchasePriceLimit
          this.policyData.capital= { amount: this.minPurchasePriceLimit};
        }

        this.getProductPrice();
      },

      checkMaxPurchasePrice(params) {
        this.showMaxPurchasePriceError = params.product_category_id == -1;

        if(this.isMultidevice() && this.policyData.risks){
          const totalPrice = this.policyData.risks.map(item => {
            return item.purchase_price !== '' ? parseFloat(item.purchase_price) : 0}).reduce((prev, curr) => prev + curr, 0
          );
          this.showMaxPurchasePriceError = totalPrice > this.policyData.purchasePrice;
        }
      },
      
      async getProductPrice() {      
        const params = getPolicyPriceParams(this.product, this.policyData);

        this.checkMaxPurchasePrice(params);
        if(this.showMaxPurchasePriceError) return;

        try {
          const {price, anualPrice, monthsWithoutInterest, deferredPaymentMonths } = await getPolicyPrices(params);
          this.price = price;
          this.anualPrice = anualPrice;
          this.monthsWithoutInterest = monthsWithoutInterest;
          this.deferredPaymentMonths = deferredPaymentMonths;

          this.showMonthlyPeriodicityOption = this.anualPrice > 1200 && this.landingData.payment_method === null;
          if(this.showPeriodicityOptions && !this.showMonthlyPeriodicityOption && this.policyData.periodicity!="12") this.updatePolicyData(12);
        } catch {
          this.handleError();
        }
      },

      savePolicyData(policyData) {
        this.disabledUserStep = false;
        this.policyData = policyData;
        this.policyData.product = this.landingData.product;
        this.policyData.product_id= this.landingData.product.product_id;
        this.policyData.store_id = this.landingData.store_id;
        this.policyData.idCategory = getProductCategoryId(policyData.purchasePrice, policyData.product);
        
        this.$nextTick(() => {
          this.step = 1;
        });
        
        this.pushGTMUserID(this.policyData.phone)
        this.pushGTM('funnel', 'confirmar_paso1', 'paso1', this.policyData.purchasePrice, this.product);
      },

      async createPolicy(userData){
        this.pushGTM('funnel', 'confirmar_paso2', 'paso2', '', this.product)
        this.userData = userData;
        let params = getSubmitPolicyParams(this.product, this.policyData, this.userData);
        params.url_ok = `${window.location.href}&tpv_ok=1`;
        params.url_ko = `${window.location.href}&tpv_ok=0`;
        try{
          const response = await submitPolicy(params);
          if(response.isValidPolicy) {
            this.createElementFromHTML(response.form).submit();
          } else {
            this.step = 0;
            this.showPolicyError = true;
          }
        } catch {
          this.step = 0;
          this.showPolicyError = true;
        }
      },

      handleError() {
        this.actived = false;
        this.error = 'contractingLanding.error';
      },

      isMultidevice() {
        return this.getGeneralProduct() === landingsIds.seguromultidispositivo.name;
      },

      getCurrentDeviceComp(){
        switch(this.product){
          case 'seguromovil':
          case 'seguromovilvoltaje':
          case 'seguromovilrobo':
            this.productFormComponent = 'SmartphoneForm';
            break;
          case 'seguroportatil':
          case 'seguroportatilvoltaje':
          case 'seguroportatilrobo':
            this.productFormComponent = 'LaptopForm';
            break;
          case 'seguromultidispositivo':
          case 'seguromultidispositivovoltaje':
            this.productFormComponent = 'MultideviceForm';
            break;
          default: 
            this.handleError();
        }
      },

       getGeneralProduct() {
        if(this.product.includes('robo')){
          return this.product.replace('robo', '');
        } else if(this.product.includes('voltaje')){
          return this.product.replace('voltaje', '');
        }
        return this.product;
      },

      createElementFromHTML(htmlString) {
        var div = document.createElement('div');
        div.innerHTML = htmlString.trim();
        document.body.appendChild(div);
        return div.firstChild;
      },     

      /*IMAGES*/
      getProductPath() {
        return require(`../assets/header/${this.$i18n.locale}_${this.getGeneralProduct()}.svg`);
      },
      getPricePath() {
        return require(`../assets/contracting-landing/precio.svg`);
      },
      getInsuramaPath() {
        try {
            return require(`@/assets/logo/${this.$i18n.locale}_${this.getGeneralProduct()}.svg`)
        } catch (e) {
            return require(`@/assets/logo/${this.$i18n.locale}_${this.getGeneralProduct()}.png`)
        }
      }
  }
}

</script>
<style lang="scss">
@import "../css/contracting-landing/contracting.scss";
</style>