/* Axios */
import axios from 'axios'
import ApiUrls from '@/config/constants/apiUrls.js'

/* Api insurama */
import { Api } from '@insurama/api-client'
var sb = new Api({ apiUrl: process.env.VUE_APP_INSURAMA_API_URL })

import i18n from '@/i18n.js'

// Functions
export async function getStores(params) {
    return await sb.getStores(params)
        .then(response => {
            return response.data.stores
        })
        .catch(() => [])
}

export async function getAllProducts() {
    return await sb.getProducts()
        .then(data => data.data)
        .then(function(data) {
            console.log(data);
            return data
                .map(function(item) {
                    //item.name = item.name.split(' ')[0]
                    /*item.coverages = Object.keys(coverages).map(function(i) {
                        var current = coverages[i]
                        return {
                            coverage_id: i,
                            label: current.label,
                            // eslint-disable-next-line eqeqeq
                            included: item.coverages.reduce(function(acc, item) { return acc || item.pivot.coverage_id == i }, false)
                        }
                    })*/
                    return item
                })
        })
        .then(function(data) {
            console.log(data);
            return data
        })
        .catch(function(err) {
            console.log(err)
        })
}

export async function getPolicy(identifier) {
    return await sb.getPolicy(identifier)
        .then(response => {
            return response.data
        })
        .catch(() => [])
}

export async function getPolicyPrice(params) {
    return await sb.getPolicyPrice(params)
        .then(data => data.data)
        .then(function(data) {
            var price = data['price_per_payment']
            if (process.env.VUE_APP_COUNTRY_CODE == 'it' || process.env.VUE_APP_COUNTRY_CODE == 'pt') {
                price.replace(".", ",");
            }

            return price
        })
        .catch(function(err) {
            console.log(err);
            return -1
        });
}

// Returns price by policy data config and anual price
export async function getPolicyPrices(params) {
    return await sb.getPolicyPrice(params)
        .then(({data}) => {
            const deferredPaymentMonths = data['deferred_payment_months'];
            const monthsWithoutInterest = data['periodicity'] - deferredPaymentMonths;
            let price;

            if (data['periodicity'] === "12") {
                price = data['price_monthly'];
            } else {
                price = data['price'];
            }

            return { price, anualPrice: data['price_annual'], monthsWithoutInterest, deferredPaymentMonths }
        })
        .catch(function(err) {
            console.log(err);
            return -1
        });
}

export async function getDeviceModels() {
    return await axios.get(ApiUrls.DEVICE_MODELS)
        .then((response) => {
            return response.data
        })
        .catch(function(err) {
            console.error(err)
        })
}

export async function createOrUpdateGenericItem(params) {
    return await sb.createOrUpdateGenericItem(params)
        .then(response => {
            return response.data
        })
        .catch(() => [])
}

export async function submitPolicy(params) {
    return await sb.createPolicy(params)
        .then(response => {
            if (response.status == 201) {
                return {
                    isValidPolicy: true,
                    policy: response.data.policy,
                    form: response.data.form
                }
            } else {
                return {
                    isValidPolicy: false,
                    error: i18n.t('error_ocurred')
                }
            }
        })
        .catch(error => {
            return {
                isValidPolicy: false,
                error: error.message
            }
        })
}

export async function createPolicyPaymentMethod(params) {
    return await sb.createPolicyPaymentMethod(params.identifier, params)
        .then(response => {
            console.log("response Payment Method");
            console.log(response);
            if (response.status == 200) {
                return {
                    form: response.data.form
                }
            } else {
                return {
                    form: null,
                    error: i18n.t('error_ocurred')
                }
            }
        })
        .catch(error => {
            return {
                isValidPolicy: false,
                error: error.message
            }
        })
}

export async function submitContact(params, config) {
    return await axios.post(ApiUrls.CONTACT_POST, params, config)
        .then(response => {
            if (response.status == 201) {
                return {
                    isValidContact: true
                }
            } else {
                return {
                    isValidContact: false,
                    error: i18n.t('error_ocurred')
                }
            }
        })
        .catch(error => {
            return {
                isValidContact: false,
                error: error.message
            }
        })
}

export async function getDistributorLandings(distributor) {
    const url = ApiUrls.DISTRIBUTOR_LANDINGS.concat(`/${distributor}`)
    return await axios.get(url)
        .then(({data}) => {
            return data.map(
                ({actived, discount_visible, store_id, logo_path, url, distributor_landing_product, payment_method, graces, privacy_policy_path, terms_and_conditions_path   })=>
                ({ actived, discount_visible, store_id, logo_path, url, products: distributor_landing_product.map(({product_id, product_type_id, multi_array, multi_array_text})=>({product_id, product_type_id, multi_array, multi_array_text})), payment_method, graces, privacy_policy_path, terms_and_conditions_path   }));
        })
        .catch(error => {
            console.error(error);
        })
}

export async function validateTicketPH(code){
    console.log(ApiUrls.VALIDATE_TICKET_PH);
    const url = ApiUrls.VALIDATE_TICKET_PH.concat(`/${code}`)
    try {
        const { data } = await axios.get(url);
        return data.ticket_response;
    } catch(error) {
        console.error(error);
    }
}

export async function getTaxStatusAll(){
    try {
        const { data } = await axios.get(ApiUrls.REGIMEN_FISCAL);
        return data.regimen_fiscal;
    } catch(error) {
        console.error(error);
    }
}

export async function getAddressByPostcode(cp){
    const url = ApiUrls.ADDRESS_BY_POSTCODE.concat(`/${cp}`)
    try {
        const { data } = await axios.get(url);
        return data;
    } catch(error) {
        console.error(error);
    }
}

export async function getStoresPH(){
    console.log('ApiUrls' + ApiUrls.STORES_PH);
    try {
        const { data } = await axios.get(ApiUrls.STORES_PH);
        return data.palacio_stores;
    } catch(error) {
        console.error(error);
    }
}

export async function markPaymentAsPaid(policyId, paymentId, params = []){
    try {
        const { data } = await sb.markPaymentAsPaid(policyId, paymentId, params);
        return data;
    } catch(error) {
        console.error(error);
    }
}

export async function checkIpIronPalace(ip){
    const url = ApiUrls.CHECK_IP_PH.concat(`/${ip}`)
    try {
        const { data } = await axios.get(url);
        return data;
    } catch(error) {
        console.error(error);
    }
}

/**
 * Gets the data for a given landing page
 *
 * @param landing
 * @returns {Promise<any>}
 */
export async function getDiscountsInABoxLandingData (landing) {
    const url = `${process.env.VUE_APP_INSURAMA_API_URL}/descuentos-in-a-box-landing/${landing}`

    const { data } = await axios.get(url)
        .catch(e => {
            console.error(e)
        })

    return data
}

/**
 * Checks if a given code is a valid one for the given distributor
 *
 * @param code String
 * @param distributorId String
 * @returns {Promise<Boolean>}
 */
export async function validateDiscountsInABoxCode (code, distributorId) {
    const url = `${process.env.VUE_APP_INSURAMA_API_URL}/discounts-in-a-box/validate/${code}/${distributorId}`;

    const { data } = await axios.get(url)
        .catch(e => {
            console.error(e);
        });

    return data;
}
